import cl from 'classnames';
import * as React from 'react';
import styled from 'styled-components';
import { ResponsiveTypography } from '../../../../../Typography/v3/ResponsiveTypography';
import { colorsV2 } from '../../../../../../figma/colors-v2';
import { shadows } from '../../../../../../figma/shadows';
import { verticalSpace } from '@doltech/utils/lib/css-style';

const Main = styled.div`
  padding: 32px;
  border-radius: 8px;
  background-color: ${colorsV2.white100};
  box-shadow: ${shadows.zIndex5};

  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: minmax(0, max-content);
  gap: 32px;

  .option-list {
    ${verticalSpace(12)}
  }

  .option-item {
    cursor: pointer;
  }
`;

interface DropdownItem {
  title: string;
  options?: any;
}

interface MultiMenuLayoutProps {
  items: DropdownItem[];
  renderItem?: (args: DropdownItem) => any;
}

export const MultiMenuLayout = (props: MultiMenuLayoutProps) => {
  const { items = [], renderItem } = props;
  return (
    <Main className="drop-down-action">
      {items.map((item) => {
        return (
          <div>
            <ResponsiveTypography.Paragraph color="black100" variant="bold/16-24" mb="16px">
              {item.title}
            </ResponsiveTypography.Paragraph>
            <div className="option-list">
              {item?.options.map((option) => {
                const { label, key, selected } = option;
                return (
                  <div key={key} className={cl('option-item', 'close-popover-on-click', selected)}>
                    {renderItem ? renderItem({ ...option }) : <div className="text">{label}</div>}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </Main>
  );
};

import cl from 'classnames';
import * as React from 'react';
import styled from 'styled-components';

import { deviceBreakpoints } from '../../../hooks/useDeviceQuery.hook';
import { colorsV2 } from '../../colors-v2';
import { LANDING_BANNER_HEIGHT, LANDING_HEADER_HEIGHT, LANDING_SIDE_BAR_WIDTH } from './constants';
import { useLandingLayoutContext } from './LandingLayout.context';
import withHydrationOnDemand from '@doltech/core/lib/hooks/withDolHydrationOnDemand';
import { fromScreen } from '@doltech/core/lib/responsive/responsive.util';

export const Main = styled.div.withConfig({
  componentId: 'Layout_LandingContent_Main',
} as any)<{ padding?: string; top }>`
  display: flex;
  z-index: 1;

  .landing-section-middle {
    flex: 1;
    overflow-x: hidden;
    overflow-y: hidden;
    max-width: 100vw;
  }

  .landing-section-container {
    &.full-width {
      width: 100vw;
      position: relative;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
    }
  }

  .sidebar-overlay {
    z-index: 200;
    position: fixed;
    top: ${LANDING_HEADER_HEIGHT}px;
    bottom: 0;
    left: 0;
    width: ${LANDING_SIDE_BAR_WIDTH.MOBILE}px;
    height: calc(100% - ${LANDING_HEADER_HEIGHT}px);
    background-color: ${colorsV2.white100};

    transform: translateX(-100%);
    opacity: 0;
    transition: all 0.2s ease;

    &.visible {
      opacity: 1;
      transform: translateX(0);
    }

    @media screen and ${deviceBreakpoints.fromTablet} {
      width: ${LANDING_SIDE_BAR_WIDTH.TABLET}px;
    }
    ${fromScreen(1280)} {
      display: none;
    }
  }

  .overlay-content {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: ${LANDING_HEADER_HEIGHT}px;
    background: rgba(0, 0, 0, 0.4);
    box-shadow: 0 24px 32px ${colorsV2.blackShadow4}, 0 16px 24px ${colorsV2.blackShadow4},
      0 4px 8px ${colorsV2.blackShadow4}, 0 0 1px ${colorsV2.blackShadow4};

    opacity: 0;
    z-index: -1;
    transition: opacity 0.2s ease;
    &.visible {
      opacity: 1;
      z-index: 150;
    }
  }

  .landing-left-sidebar {
    z-index: 2;

    &:not(.overlay) {
      padding: 24px 12px;
    }

    &.overlay {
      width: 100%;
      height: 100%;
      z-index: 4;
      padding: 24px;
      overflow-y: auto;
    }
  }

  .landing-section-right.empty {
    width: 300px;
    min-width: 300px;
    height: 100%;
    min-height: 1px;
    margin-left: 16px;
  }

  @media ${deviceBreakpoints.allExceptDesktop} {
    .landing-section-right {
      display: none;
    }
    .landing-section-right.empty {
      display: none;
      margin-left: 0;
    }
    .empty-sidebar {
      display: none;
    }
  }
`;

interface LandingContentProps extends React.HTMLAttributes<HTMLDivElement> {
  padding?: string;
  className?: string;
  children?: JSX.Element | JSX.Element[];
}

export const LandingContent = ({ children, className, ...rest }: LandingContentProps) => {
  const [topValue, setTop] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = () => {
      const { top } = document.body.getBoundingClientRect();
      setTop(top === 0);
    };
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, []);

  return (
    <Main
      top={topValue ? LANDING_HEADER_HEIGHT + LANDING_BANNER_HEIGHT : LANDING_HEADER_HEIGHT}
      id="layout-landing-content"
      className={cl('landing-content', className)}
      {...rest}
    >
      {children}
    </Main>
  );
};

interface LeftSidebarComponentProps extends React.HTMLAttributes<HTMLDivElement> {
  displayAsSideBar?: boolean;
  disabled?: boolean;
  disableOutsideClick?: boolean;
}

const SectionLeft = ({ className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cl('landing-section-left', className)} {...rest} />
);

const LeftOverlaySidebar = React.forwardRef((props: LeftSidebarComponentProps, ref) => {
  const {
    displayAsSideBar,
    className,
    disabled = false,
    disableOutsideClick = false,
    ...rest
  } = props;
  const contextValue = useLandingLayoutContext();
  const isVisible = contextValue.sideBarOpen && !disabled;

  return (
    <>
      <div ref={ref as any} className={cl('sidebar-overlay', { visible: isVisible })}>
        <div
          key="landing-content-sidebar"
          className={cl(
            'landing-left-sidebar',
            'overlay',
            { sidebar: displayAsSideBar },
            className
          )}
          {...rest}
        />
      </div>
      <div
        onClick={() => {
          if (disableOutsideClick) return;
          contextValue.setSideBarOpen(false);
        }}
      />
    </>
  );
});

interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  fullWidth?: boolean;
}

const SectionWrapper = styled.article`
  height: auto !important;
`;
const SectionMiddle = ({ className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <SectionWrapper className={cl('landing-section-middle', className)} {...rest} />
);

const SectionRight = ({ className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cl('landing-section-right', className)} {...rest} />
);

const Container = ({ className, fullWidth = false, ...rest }: ContainerProps) => (
  <div
    className={cl('landing-section-container', { 'full-width': fullWidth }, className)}
    {...rest}
  />
);

LandingContent.SectionLeft = SectionLeft;
LandingContent.SectionMiddle = SectionMiddle;
LandingContent.SectionRight = SectionRight;
LandingContent.Container = Container;
LandingContent.LeftOverlaySidebar = withHydrationOnDemand({ on: ['idle'] })(LeftOverlaySidebar);

import {
  PageTypeForSpeakingSampleAsPagePath,
  PageTypeForWritingSampleAsPagePath,
} from '@doltech/utils/lib/constants';
import { fixRedirectUrl } from '@doltech/utils/lib/url';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import styled from 'styled-components';
import { Link } from '../../../../Link';
import { colorsV2 } from '../../../../colors-v2';
import { useLandingSharedDataStore } from '../landing-shared-data.hook';
import { MultiMenuLayout } from './layout/MultiMenuLayout';

const Main = styled.div<{ $width?: number }>`
  width: ${(p) => p.$width || 232}px;
  padding-top: 10px;
  .content-wrapper {
    a {
      color: ${colorsV2.black100};
    }
  }
`;

export const TopNavSelectOptionSampleList = observer<any>(({ width, navItems }) => {
  const landingStore = useLandingSharedDataStore();
  const actions = React.useMemo(
    () =>
      navItems.map((navItem) => ({
        key: navItem.pageType,
        label: navItem.title,
        external: Boolean(navItem?.basePath),
        url: navItem?.basePath
          ? fixRedirectUrl(
              `${navItem?.basePath}/${
                landingStore?.pageLink?.staticPageLink?.[navItem.pageType]?.url
              }`
            )
          : landingStore?.pageLink?.staticPageLink?.[navItem.pageType]?.url,
      })),
    [navItems, landingStore?.pageLink?.staticPageLink]
  );

  return (
    <Main $width={width} className="top-nav-select-option-list-sub-navigation">
      <div className="content-wrapper">
        <MultiMenuLayout
          items={[
            {
              title: 'BÀI MẪU WRITING',
              options: actions.filter((item) =>
                PageTypeForWritingSampleAsPagePath.includes(item.key)
              ),
            },
            {
              title: 'BÀI MẪU SPEAKING',
              options: actions.filter((item) =>
                PageTypeForSpeakingSampleAsPagePath.includes(item.key)
              ),
            },
          ]}
          renderItem={({ label, key, url, external }: any) => {
            return (
              <Link
                key={key}
                target={external ? '_blank' : undefined}
                className="text"
                href={url || '#'}
              >
                <div className="sub-navigation-item">{label}</div>
              </Link>
            );
          }}
        />
      </div>
    </Main>
  );
});

import { environment } from '@doltech/core/lib/environment/environment';
import { ieltsOnlineTestSidebar, ieltsPractice } from './menu-item/ielts-practice';
import {
  dailyNews,
  dictation,
  ieltsSample,
  ieltsCourse,
  ieltsSpeakingPart1,
  ieltsSpeakingPart2,
  ieltsSpeakingPart3,
  ieltsWritingSampleTask1Aca,
  ieltsWritingSampleTask1General,
  ieltsWritingSampleTask2Aca,
} from './menu-item/menu-item';

export const topNavs = [ieltsCourse, dailyNews, ieltsPractice, dictation, ieltsSample];

const courseOptions = [
  {
    title: 'IELTS cấp tốc',
    id: 'IELTS_CRASH',
    subs: [
      {
        title: 'IELTS cấp tốc 5.0',
        url: `${environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME}/ielts/ielts-intensive-0`,
      },
      {
        title: 'IELTS cấp tốc 6.0',
        url: `${environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME}/ielts/ielts-intensive-1`,
      },
      {
        title: 'IELTS cấp tốc 7.0',
        url: `${environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME}/ielts/ielts-intensive-2`,
      },
      {
        title: 'IELTS siêu cấp tốc 6.5',
        url: `${environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME}/ielts/ielts-super-intensive`,
      },
    ],
  },
  {
    title: 'IELTS cơ bản',
    id: 'IELTS_BASIC',
    subs: [
      {
        title: 'PRE IELTS',
        url: `${environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME}/ielts/ielts-pre`,
      },
      {
        title: 'IELTS 4.0+',
        url: `${environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME}/ielts/ielts-4`,
      },
      {
        title: 'IELTS 5.0+',
        url: `${environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME}/ielts/ielts-5`,
      },
    ],
  },
  {
    title: 'IELTS nâng cao',
    id: 'IELTS_ADVANCE',
    subs: [
      {
        title: 'IELTS 6.0+',
        url: `${environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME}/ielts/ielts-6`,
      },
      {
        title: 'IELTS 6.5+',
        url: `${environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME}/ielts/ielts-65`,
      },
      {
        title: 'IELTS 7.0+',
        url: `${environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME}/ielts/ielts-7`,
      },
      {
        title: 'IELTS Writing & Speaking',
        url: `${environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME}/category/ielts-writing`,
      },
    ],
  },
  {
    title: 'IELTS Online & 1-1',
    id: 'IELTS_ONLINE',
    subs: [
      {
        title: 'IELTS Online',
        url: `${environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME}/hoc-ielts-online`,
      },
    ],
  },
  {
    title: 'Khóa học khác',
    id: 'SAT',
    subs: [
      {
        title: 'SAT',
        url: `${environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME}/sat`,
      },
      {
        title: 'TOEIC',
        url: `${environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME}/toeic`,
      },
      {
        title: 'Tiếng Anh giao tiếp',
        url: `${environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME}/tieng-anh-giao-tiep`,
      },
    ],
  },
];

const courseIelts = {
  id: 'IELTS_COURSE',
  title: 'Khoá học IELTS',
  subs: courseOptions,
};

const ieltsSamples = {
  title: 'Bài mẫu IELTS',
  id: 'IELTS_SAMPLE',
  subs: [
    {
      title: 'Bài mẫu Writing',
      id: 'IELTS_SAMPLE_WRITING',
      subs: [
        ieltsWritingSampleTask1General,
        ieltsWritingSampleTask1Aca,
        ieltsWritingSampleTask2Aca,
      ],
    },
    {
      title: 'Bài mẫu Speaking',
      id: 'IELTS_SAMPLE_SPEAKING',
      subs: [ieltsSpeakingPart1, ieltsSpeakingPart2, ieltsSpeakingPart3],
    },
  ],
};

export const sidebarMenus = [
  courseIelts,
  dailyNews,
  dictation,
  ieltsOnlineTestSidebar,
  ieltsSamples,
];

import { IconOtherSearch } from '@doltech/icons/IconOtherSearch';
import dynamic from 'next/dynamic';
import * as React from 'react';
import styled from 'styled-components';

import { Button } from '../ButtonV2/ButtonV2';
import { SkeletonCompsSingleLine } from '../SkeletonComps/SingleLine/SkeletonCompsSingleLine';

const SearchBarOpen = dynamic<any>(
  import('./SearchBarOpen').then((mod) => mod.SearchBarOpen),
  { ssr: false, loading: () => <SkeletonCompsSingleLine fill /> }
);

const Main = styled.div.withConfig({
  componentId: 'SearchEngine_SearchMobileComponent_Main',
} as any)``;
export const SearchMobileComponent = ({ useEffect = true }) => {
  const [showButtonSearch, toggleSearchButton] = React.useState(true);

  return (
    <React.Suspense>
      <Main>
        {showButtonSearch ? (
          <Button.Icon aria-label="Search Button" onClick={() => toggleSearchButton(false)}>
            <IconOtherSearch />
          </Button.Icon>
        ) : (
          <SearchBarOpen toggleSearchButton={toggleSearchButton} />
        )}
      </Main>
    </React.Suspense>
  );
};

import { PageTypeEnum } from '@doltech/utils/lib/constants';

import { TOP_NAVS_ID } from '../../../constants';
import { TopNavSelectOptionListSubNavigation } from '../TopNavSelectOptionListSubNavigation';

export const ieltsVocab = {
  id: TOP_NAVS_ID.VOCAB,
  title: TOP_NAVS_ID.VOCAB,
  pageType: PageTypeEnum.LANDING_VOCAB_IELTS_BLOG,
};

export const ieltsGrammar = {
  id: TOP_NAVS_ID.GRAMMAR,
  title: TOP_NAVS_ID.GRAMMAR,
  pageType: PageTypeEnum.LANDING_GRAMMAR_IELTS_BLOG,
};

const ieltsOnlineTestLink = {
  id: TOP_NAVS_ID.ONLINE_TEST,
  title: TOP_NAVS_ID.ONLINE_TEST,
  pageType: PageTypeEnum.LANDING_FULL_TEST,
};

export const ieltsListeningPractice = {
  id: TOP_NAVS_ID.IELTS_LISTENING_PRACTICE,
  title: TOP_NAVS_ID.IELTS_LISTENING_PRACTICE,
  pageType: PageTypeEnum.LANDING_LISTENING_PRACTICE_TEST,
};

export const ieltsReadingPractice = {
  id: TOP_NAVS_ID.IELTS_READING_PRACTICE,
  title: TOP_NAVS_ID.IELTS_READING_PRACTICE,
  pageType: PageTypeEnum.LANDING_READING_PRACTICE_TEST,
};

export const ieltsOnlineTestSidebar = {
  id: TOP_NAVS_ID.IELTS_FULL_TEST,
  title: TOP_NAVS_ID.ONLINE_TEST,
  subs: [ieltsOnlineTestLink, ieltsListeningPractice, ieltsReadingPractice],
};

export const ieltsPractice = {
  id: TOP_NAVS_ID.IELTS_PRACTICE,
  title: TOP_NAVS_ID.IELTS_PRACTICE,
  subs: [ieltsOnlineTestLink, ieltsListeningPractice, ieltsReadingPractice],
  subMode: 'popover',
  subComponent: TopNavSelectOptionListSubNavigation,
  pageType: PageTypeEnum.LANDING_FULL_TEST,
};

import { observer } from 'mobx-react-lite';
import * as React from 'react';
import styled from 'styled-components';
import { SelectOptionList } from '../../../../../syllabus/components/ButtonNewSyllabus/SelectOptionList';
import { colorsV2 } from '../../../../colors-v2';
import { Link } from '../../../../Link';
import { useLandingSharedDataStore } from '../landing-shared-data.hook';

const Main = styled.div`
  width: 214px;
  padding-top: 10px;
  .content-wrapper {
    .sub-navigation-item {
      padding: 10px 16px;
    }
    a {
      color: ${colorsV2.black100};
    }
  }
`;

export const TopNavSelectOptionListSubNavigation = observer<any>(
  ({ navItems, openNewTab = false }) => {
    const landingStore = useLandingSharedDataStore();
    const actions = React.useMemo(
      () =>
        navItems.map((navItem) => ({
          key: navItem.pageType,
          label: navItem.title,
        })),
      [navItems]
    );
    return (
      <Main className="top-nav-select-option-list-sub-navigation">
        <div className="content-wrapper">
          <SelectOptionList
            onSelect={() => {}}
            items={actions}
            renderItem={({ label, key }) => {
              return (
                <Link
                  className="text"
                  target={openNewTab ? '_blank' : undefined}
                  href={landingStore?.pageLink?.staticPageLink?.[key]?.url || '#'}
                >
                  <div className="sub-navigation-item">{label}</div>
                </Link>
              );
            }}
          />
        </div>
      </Main>
    );
  }
);

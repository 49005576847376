import * as React from 'react';
import styled from 'styled-components';
import { colorsV2 } from '../colors-v2';

const SignUpBtnPlaceHolder = styled.div`
  background-color: ${colorsV2.gray10};
  width: 83px;
  height: 40px;
  border-radius: 8px;
`;

export const FreeUserSignUpLoading = () => {
  return <SignUpBtnPlaceHolder />;
};

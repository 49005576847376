import cl from 'classnames';
import * as React from 'react';
import styled from 'styled-components';

import { colorsV2 } from '../../colors-v2';

const Line = styled.div<{ $height?: string }>`
  height: ${(p) => p.$height || '16px'};

  background-color: ${colorsV2.gray10};
  border-radius: 4px;
`;

const Limit = styled(Line)<{ $maxWidth?: string }>`
  width: 100%;
  max-width: ${(p) => p.$maxWidth || '1em'};
`;

const Fill = styled(Line)`
  width: 100%;
`;

interface SkeletonCompsSingleLineProps {
  className?: string;
  maxWidth?: string;
  fill?: boolean;
  height?: string;
}

export const SkeletonCompsSingleLine = (props: SkeletonCompsSingleLineProps) => {
  const { className, maxWidth, fill = false, height = '16px' } = props;

  if (fill) {
    return <Fill className={cl('skeleton-comp-single-line', className)} $height={height} />;
  }

  return (
    <Limit
      className={cl('skeleton-comp-single-line', className)}
      $height={height}
      $maxWidth={maxWidth}
    />
  );
};

import { IconButtonsMenu } from '@doltech/icons/IconButtonsMenu';
import { PageTypeEnum } from '@doltech/utils/lib/constants';
import { imgProxyLoader } from '@doltech/utils/lib/image-loader';
import cl from 'classnames';
import { observer } from 'mobx-react-lite';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import * as React from 'react';
import styled from 'styled-components';

import { deviceBreakpoints } from '../../../hooks/useDeviceQuery.hook';
import { Button } from '../../ButtonV2/ButtonV2';
import { colorsV2 } from '../../colors-v2';
import { Typography } from '../../DesignSystemV2/Typography';
import { DolLogo } from '../../DolLogo';
import { Link } from '../../Link';
import { LANDING_HEADER_HEIGHT } from './constants';
import { useLandingLayoutContext } from './LandingLayout.context';
import { useLandingSharedDataStore } from './model/landing-shared-data.hook';

const DolLogoBlackWhite = dynamic(
  import('../../DolLogo/DolLogoBlackWhite').then((mod) => mod.DolLogoBlackWhite),
  { ssr: false }
);

const HeaderBanner = dynamic(
  import('../../../banner/component/HeaderBanner').then((mod) => mod.HeaderBanner),
  { ssr: false }
);

const IconArrowsArrowLeft2 = dynamic<any>(
  import('@doltech/icons/IconArrowsArrowLeft2').then((mod) => mod.IconArrowsArrowLeft2),
  {
    ssr: false,
    loading: () => <span>&nbsp;</span>,
  }
);
const IconOtherClose = dynamic(
  import('@doltech/icons/IconOtherClose').then((mod) => mod.IconOtherClose),
  {
    ssr: false,
    loading: () => <span>&nbsp;</span>,
  }
);

const Main = styled.div.withConfig({
  componentId: 'Layout_LandingHeader_Main',
} as any)`
  position: relative;
  z-index: 112;
  display: flex;
  padding: 0;
  padding-bottom: 8px;
  align-items: center;
  background: ${colorsV2.white100};
  transition: all 0.2s ease;
  &.hide {
    max-height: ${LANDING_HEADER_HEIGHT}px;
    transition: max-height 0.35s ease-out;
  }

  box-shadow: 0 10px 20px ${colorsV2.blackShadow4}, 0 2px 6px ${colorsV2.blackShadow4},
    0 0 1px ${colorsV2.blackShadow4};

  .actions-container {
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
  }

  @media ${deviceBreakpoints.fromTablet} {
    .actions-container {
      align-items: center;
    }
  }
`;

const BasicInfoContainer = styled.header`
  .title {
    display: flex;
    align-items: center;
  }
`;

interface LandingHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  enableHeadRoom?: boolean;
  showBanner?: boolean;
}

export const LandingHeader = (props: LandingHeaderProps) => {
  const { children, className, enableHeadRoom, showBanner = false, ...rest } = props;
  return (
    <Main id="landing-header" className={cl('landing-header', className)} {...rest}>
      {showBanner && <HeaderBanner />}
      {children}
    </Main>
  );
};

const BtnSidebar = () => {
  const contextValue = useLandingLayoutContext();
  return (
    <Button.Icon
      aria-label="Hamburger Button"
      className="btn-sidebar"
      type="secondary"
      size="large"
      onClick={contextValue.toggleSideBarOpen}
    >
      {contextValue.sideBarOpen ? <IconOtherClose /> : <IconButtonsMenu />}
    </Button.Icon>
  );
};

interface LogoContainerProps {
  logoColored?: boolean;
}

const LogoContainer = observer(({ logoColored = true }: LogoContainerProps) => {
  const store = useLandingSharedDataStore();
  return (
    <Link href={store?.getPageToNavigate(PageTypeEnum.LANDING_HOME) || '/'}>
      <div className="logo-container">
        {logoColored ? <DolLogo withText colored={logoColored} /> : <DolLogoBlackWhite />}
      </div>
    </Link>
  );
});

const ActionContainer = ({ children }) => {
  return <div className="actions-container">{children}</div>;
};

const BackBtn = ({ hasLabel = true, onClick }) => {
  return (
    <Button.Link onClick={onClick}>
      <IconArrowsArrowLeft2 fontSize={24} color="gray100" />
      {hasLabel && (
        <Typography.Text variant="semi-bold/14" style={{ paddingLeft: 10 }} color="gray100">
          Quay lại
        </Typography.Text>
      )}
    </Button.Link>
  );
};

interface ThumbnailModel {
  url?: string;
  alt?: string;
}

interface BasicInfoProps {
  thumbnail: ThumbnailModel;
  title: string;
}

const BasicInfo = (props: BasicInfoProps) => {
  const { thumbnail, title } = props;
  return (
    <BasicInfoContainer className="basic-info-container">
      <Image
        layout="responsive"
        width={66}
        height={46}
        src={thumbnail?.url || 'https://dummyimage.com/66x46.png'}
        alt={thumbnail?.alt || title}
        style={{ borderRadius: 8, objectFit: 'cover' }}
        loader={imgProxyLoader}
      />
      <div className="title-container">
        <div className="title">
          <Typography.Title level={1} variant="semi-bold/20">
            {title}
          </Typography.Title>
        </div>
      </div>
    </BasicInfoContainer>
  );
};

LandingHeader.BtnSidebar = BtnSidebar;
LandingHeader.LogoContainer = LogoContainer;
LandingHeader.ActionContainer = ActionContainer;
LandingHeader.BackBtn = BackBtn;
LandingHeader.BasicInfo = BasicInfo;

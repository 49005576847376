import cl from 'classnames';
import { noop } from 'lodash';
import * as React from 'react';
import styled from 'styled-components';

import { colorsV2 } from '../../../figma/colors-v2';
import { shadows } from '../../../figma/shadows';

const Main = styled.div<{ $width?: string }>`
  width: ${(p) => p.$width || '232px'};
  padding: 12px 0;
  border-radius: 8px;
  background-color: ${colorsV2.white100};
  box-shadow: ${shadows.zIndex4};
  border: 1px solid ${colorsV2.gray20};

  .option-item {
    cursor: pointer;
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    &:hover {
      background-color: ${colorsV2.gray10};
    }
    &.selected {
      background-color: ${colorsV2.blue10};
    }
  }
  .text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${colorsV2.black100};
  }
`;

interface DropdownItem {
  label: string;
  key: string;
  selected?: boolean;
}

interface SelectOptionListsProps {
  width?: string;
  items: DropdownItem[];
  onSelect?: (item: DropdownItem) => void;
  renderItem?: (args: DropdownItem) => any;
}

export const SelectOptionList = (props: SelectOptionListsProps) => {
  const { width, items = [], onSelect = noop, renderItem } = props;
  return (
    <Main $width={width} className="drop-down-action">
      {items.map((item) => {
        const { label, key, selected } = item;
        return (
          <div
            onClick={() => {
              onSelect(item);
            }}
            key={key}
            className={cl('option-item', 'close-popover-on-click', selected)}
          >
            {renderItem ? renderItem({ ...item }) : <div className="text">{label}</div>}
          </div>
        );
      })}
    </Main>
  );
};

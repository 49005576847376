import * as React from 'react';
import styled from 'styled-components';

import { deviceBreakpoints } from '../../../../hooks/useDeviceQuery.hook';
import { SearchMobileComponent } from '../../../SearchEngine/SearchMobileComponent';

const Main = styled.div.withConfig({
  componentId: 'Header_HeaderActionSearch_Main',
} as any)`
  .base {
    display: block;
  }
  .sm {
    display: none;
    > div {
      width: 100%;
    }
  }
  @media ${deviceBreakpoints.allExceptMobile} {
    .base {
      display: none;
    }
    .sm {
      display: block;
    }
  }

  &.search-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    @media ${deviceBreakpoints.mobile} {
      justify-content: flex-end;
    }
    > div {
      width: 100%;
    }
  }
`;

export const HeaderActionSearch = () => {
  return (
    <Main className="search-container">
      <SearchMobileComponent />
    </Main>
  );
};

import { observer } from 'mobx-react-lite';
import dynamic from 'next/dynamic';
import * as React from 'react';
import styled from 'styled-components';

import { Button } from '../ButtonV2/ButtonV2';
import { colorsV2 } from '../colors-v2';
import { FreeUserSignUpLoading } from './FreeUserSignUpLoading';
import { useUserAuthentication } from './hocs/withUserAuthentication';
import { useGetAuthInfo } from '@doltech/core/lib/auth/withGlobalUserAuthInfo';
import { toScreen } from '@doltech/core/lib/responsive/responsive.util';

const AvatarLoading = styled.div`
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
  border-radius: 50%;
  background-color: ${colorsV2.gray20};
`;

const HeaderActionSignUp = dynamic(
  () => import('../Layout/Landing/Header/HeaderActionSignUp').then((mod) => mod.HeaderActionSignUp),
  {
    ssr: false,
    loading: () => <AvatarLoading />,
  }
);

const Main = styled.div`
  ${toScreen(547)} {
    display: none;
  }
`;

export const FreeUserSignUp = observer(() => {
  const { isLoggedIn, isLoading } = useGetAuthInfo();
  const { openLoginModal } = useUserAuthentication();

  const renderSignUp = React.useCallback(() => {
    if (isLoading) {
      return <FreeUserSignUpLoading />;
    }
    if (isLoggedIn) {
      return <HeaderActionSignUp />;
    }
    return (
      <Main>
        <Button.Primary variant="normal" size="large" onClick={openLoginModal}>
          Đăng ký
        </Button.Primary>
      </Main>
    );
  }, [isLoading, isLoggedIn, openLoginModal]);

  return renderSignUp();
});

import * as React from 'react';
import { DolTooltip, DolTooltipProps } from '../Tooltip/DolTooltip';
import { colorsV2 } from '../colors-v2';
import styled from 'styled-components';
import renderNode, { Div } from '../renderNode';

export interface DolPopoverProps extends Omit<DolTooltipProps, 'title'> {
  content?: any;
  title?: any;
}

const Main = styled.div`
  position: relative;
  .popover-title {
    padding: 5px 16px 4px;
    font-weight: 500;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }
  .popover-inner-content {
    padding: 12px 16px;
  }
`;

export const DolPopover = ({
  placement,
  children,
  title,
  content,
  color,
  ...rest
}: DolPopoverProps) => {
  return (
    <DolTooltip
      placement={placement || 'top'}
      color={color || colorsV2.white100}
      title={(props) => {
        return (
          <Main className="popover-content">
            <div className="popover-inner" role="tooltip">
              {title && <div className="popover-title">{title}</div>}
              <div className="popover-inner-content">{renderNode(Div, content, props)}</div>
            </div>
          </Main>
        );
      }}
      {...rest}
    >
      {(renderProps) => renderNode(Div, children, renderProps)}
    </DolTooltip>
  );
};

import dynamic from 'next/dynamic';
import * as React from 'react';

import classes from './SubNavigationDrawer.module.less';

const NavHeaderDrawer = dynamic<any>(
  () =>
    import('../../../../../MotionDrawer/NavHeaderDrawer').then((mod) => mod.NavHeaderDrawer as any),
  {
    ssr: false,
  }
);

interface SubNavigationDrawerProps extends React.HTMLAttributes<HTMLDivElement> {
  visible?: boolean;
  onClose?: () => void;
  parentNavEl?: any;
}

export const SubNavigationDrawer = (props: SubNavigationDrawerProps) => {
  const { children, ...rest } = props;

  return (
    <NavHeaderDrawer className={classes.subNavDrawer} {...rest}>
      {children}
    </NavHeaderDrawer>
  );
};

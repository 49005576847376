import { fromWidth } from '@doltech/core/lib/responsive/media-query';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useIsomorphicLayoutEffect, useMount } from 'react-use';
import { isBrowser, off, on } from 'react-use/lib/misc/util';

export const SHARED_MOBILE_CONTENT_WIDTH = 344;
export const SHARED_LARGE_MOBILE_CONTENT_WIDTH = 396;
export const SHARED_TABLET_CONTENT_WIDTH = 712;
export const SHARED_DESKTOP_CONTENT_WIDTH = 1144;

export const deviceScreenSizes = {
  mobile: {
    min: 320,
    max: 767,
  },
  lgMobile: {
    min: 428,
    max: 767,
  },
  tablet: {
    min: 768,
    max: 1279,
  },
  desktop: {
    min: 1280,
  },
  lgDesktop: {
    min: 1681,
  },
};

export const deviceBreakpoints = {
  mobile: `(max-width: ${deviceScreenSizes.mobile.max}px)`,
  tablet: `(min-width: ${deviceScreenSizes.tablet.min}px) and (max-width: ${deviceScreenSizes.tablet.max}px)`,
  desktop: `(min-width: ${deviceScreenSizes.desktop.min}px)`,
  lgDesktop: `(min-width: ${deviceScreenSizes.lgDesktop.min}px)`,
  allExceptMobile: `(min-width: ${deviceScreenSizes.tablet.min}px)`,
  allExceptDesktop: `(max-width: ${deviceScreenSizes.tablet.max}px)`,

  fromLgMobile: `(min-width: ${deviceScreenSizes.lgMobile.min}px)`, // large mobile
  fromTablet: `(min-width: ${deviceScreenSizes.tablet.min}px)`, // tablet
  fromDesktop: `(min-width: ${deviceScreenSizes.desktop.min}px)`, // desktop
  fromLgDesktop: `(min-width: ${deviceScreenSizes.lgDesktop.min}px)`, // large desktop
  lgMobile: `(min-width: ${deviceScreenSizes.lgMobile.min}px) and (max-width: ${deviceScreenSizes.lgMobile.max}px)`,
};

export const responsiveBreakPoints = {
  'above-528': '(min-width: 529px)',
  'above-540': '(min-width: 541px)',
  'above-719': '(min-width: 720px)',
  'above-1024': '(min-width: 1024px)',
  'above-1136': '(min-width: 1136px)',
  'above-1280': '(min-width: 1280px)',
};

const isArrChanged = (firstArr: any[], secondArr: any[]) => {
  if (firstArr.length !== secondArr.length) {
    return true;
  }

  const leng = firstArr.length;

  for (let i = 0; i < leng; i++) {
    if (firstArr[i] !== secondArr[i]) {
      return true;
    }
  }
  return false;
};

const matchAllMediasByMinWidths = (minWidths) => {
  return minWidths.map((query) => window.matchMedia(fromWidth(query)));
};

export const useFromScreens = (fromScreens: number[]) => {
  const [, forceRender] = React.useState(0);
  const screensRef = React.useRef(
    fromScreens.map((_, index) => {
      return index !== 0;
    })
  );

  useMount(() => {
    const values = matchAllMediasByMinWidths(fromScreens).map((query) => query.matches);

    if (isArrChanged(values, screensRef.current)) {
      forceRender(Date.now());
    }
    screensRef.current = values;
  });

  React.useEffect(() => {
    let mounted = true;
    const mediaQueries = matchAllMediasByMinWidths(fromScreens);
    const onChange = () => {
      if (!mounted) {
        return;
      }
      const values = mediaQueries.map((query) => !!query.matches);
      if (isArrChanged(values, screensRef.current)) {
        forceRender(Date.now());
      }
      screensRef.current = values;
    };

    on(window, 'resize', onChange);

    return () => {
      mounted = false;
      off(window, 'resize', onChange);
    };
  }, [fromScreens]);

  return screensRef.current;
};

export const useDeviceDetect = () => {
  const [isClient, setIsClient] = React.useState(false);

  const isMobile = useMediaQuery({ query: deviceBreakpoints.mobile });
  const isTablet = useMediaQuery({ query: deviceBreakpoints.tablet });
  const isDesktop = useMediaQuery({ query: deviceBreakpoints.desktop });
  const isLargeDesktop = useMediaQuery({ query: deviceBreakpoints.lgDesktop });
  const allExceptDesktop = useMediaQuery({ query: deviceBreakpoints.allExceptDesktop });
  const allExceptMobile = useMediaQuery({ query: deviceBreakpoints.allExceptMobile });

  useIsomorphicLayoutEffect(() => {
    if (isBrowser) {
      setIsClient(true);
    }
  }, []);

  if (isClient) {
    return {
      isMobile,
      isTablet,
      isDesktop,
      isLargeDesktop,
      allExceptMobile,
      allExceptDesktop,
    };
  }

  // set default value when in SSR mode
  return {
    isMobile: false,
    isTablet: false,
    isDesktop: true,
    isLargeDesktop: false,
    allExceptMobile: true,
    allExceptDesktop: false,
  };
};

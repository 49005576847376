import { environment } from '@doltech/core/lib/environment/environment';
import { ResponsiveTypography } from '../../../../Typography/v3/ResponsiveTypography';
import * as React from 'react';
import styled from 'styled-components';
import { Link } from '../../../../Link';
import { colorsV2 } from '../../../../colors-v2';
import { shadows } from '../../../../shadows';

const Main = styled.div`
  width: 100vw;
  background-color: ${colorsV2.white100};
  box-shadow: ${shadows.topZIndex4};
`;

const Content = styled.div`
  max-width: 1366px;
  margin: 0 auto;

  display: grid;
  grid-template-columns: 4fr 1fr;
`;

const LeftArea = styled.div`
  padding: 40px 20px 40px 32px;
`;

const RightArea = styled.div`
  position: relative;
  z-index: 3;
  padding: 40px 20px 40px 32px;
  background-color: rgb(249, 250, 250);

  &::before {
    z-index: -1;
    content: ' ';
    background: rgb(249, 250, 250);
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    width: 100vw;
  }
`;

const CourseList = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, max-content));
  gap: 100px;
`;

export const TopNavSelectCourseOptionListSubNavigationExternal = () => {
  return (
    <Main className="top-nav-select-option-list-sub-navigation">
      <Content>
        <LeftArea>
          <ResponsiveTypography.Paragraph variant="bold/20-28" color="black100" mb="24px">
            KHÓA HỌC IELTS
          </ResponsiveTypography.Paragraph>
          <CourseList>
            <div>
              <ResponsiveTypography.Paragraph variant="semi-bold/16-20" color="black100" mb="16px">
                IELTS CẤP TỐC
              </ResponsiveTypography.Paragraph>
              <Link
                href={`${environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME}/ielts/ielts-intensive-0`}
                target="_blank"
                rel="nofollow"
              >
                <ResponsiveTypography.Paragraph variant="medium/14-20" color="black100" mb="12px">
                  IELTS cấp tốc 5.0
                </ResponsiveTypography.Paragraph>
              </Link>
              <Link
                href={`${environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME}/ielts/ielts-intensive-1`}
                target="_blank"
                rel="nofollow"
              >
                <ResponsiveTypography.Paragraph variant="medium/14-20" color="black100" mb="12px">
                  IELTS cấp tốc 6.0
                </ResponsiveTypography.Paragraph>
              </Link>

              <Link
                href={`${environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME}/ielts/ielts-intensive-2`}
                target="_blank"
                rel="nofollow"
              >
                <ResponsiveTypography.Paragraph variant="medium/14-20" color="black100" mb="12px">
                  IELTS cấp tốc 7.0
                </ResponsiveTypography.Paragraph>
              </Link>

              <Link
                href={`${environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME}/ielts/ielts-super-intensive`}
                target="_blank"
              >
                <ResponsiveTypography.Paragraph variant="medium/14-20" color="black100" mb="12px">
                  IELTS siêu cấp tốc 6.5
                </ResponsiveTypography.Paragraph>
              </Link>
            </div>
            <div>
              <ResponsiveTypography.Paragraph variant="semi-bold/16-20" color="black100" mb="16px">
                IELTS CƠ BẢN
              </ResponsiveTypography.Paragraph>

              <Link
                href={`${environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME}/ielts/ielts-pre`}
                target="_blank"
              >
                <ResponsiveTypography.Paragraph variant="medium/14-20" color="black100" mb="12px">
                  PRE IELTS
                </ResponsiveTypography.Paragraph>
              </Link>
              <Link
                href={`${environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME}/ielts/ielts-4`}
                target="_blank"
              >
                <ResponsiveTypography.Paragraph variant="medium/14-20" color="black100" mb="12px">
                  IELTS 4.0+
                </ResponsiveTypography.Paragraph>
              </Link>
              <Link
                href={`${environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME}/ielts/ielts-5`}
                target="_blank"
              >
                <ResponsiveTypography.Paragraph variant="medium/14-20" color="black100" mb="12px">
                  IELTS 5.0+
                </ResponsiveTypography.Paragraph>
              </Link>
            </div>
            <div>
              <ResponsiveTypography.Paragraph variant="semi-bold/16-20" color="black100" mb="16px">
                IELTS NÂNG CAO
              </ResponsiveTypography.Paragraph>

              <Link
                href={`${environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME}/ielts/ielts-6`}
                target="_blank"
              >
                <ResponsiveTypography.Paragraph variant="medium/14-20" color="black100" mb="12px">
                  IELTS 6.0+
                </ResponsiveTypography.Paragraph>
              </Link>

              <Link
                href={`${environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME}/ielts/ielts-65`}
                target="_blank"
              >
                <ResponsiveTypography.Paragraph variant="medium/14-20" color="black100" mb="12px">
                  IELTS 6.5+
                </ResponsiveTypography.Paragraph>
              </Link>

              <Link
                href={`${environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME}/ielts/ielts-7`}
                target="_blank"
              >
                <ResponsiveTypography.Paragraph variant="medium/14-20" color="black100" mb="12px">
                  IELTS 7.0+
                </ResponsiveTypography.Paragraph>
              </Link>

              <Link
                href={`${environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME}/ielts/writing-speaking`}
                target="_blank"
              >
                <ResponsiveTypography.Paragraph variant="medium/14-20" color="black100" mb="12px">
                  IELTS Writing & Speaking
                </ResponsiveTypography.Paragraph>
              </Link>
            </div>
            <div>
              <ResponsiveTypography.Paragraph variant="semi-bold/16-20" color="black100" mb="16px">
                IELTS ONLINE & 1-1
              </ResponsiveTypography.Paragraph>
              <Link
                href={`${environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME}/hoc-ielts-online`}
                target="_blank"
              >
                <ResponsiveTypography.Paragraph variant="medium/14-20" color="black100" mb="12px">
                  IELTS Online
                </ResponsiveTypography.Paragraph>
              </Link>
            </div>
          </CourseList>
        </LeftArea>

        <RightArea>
          <ResponsiveTypography.Paragraph variant="bold/20-28" color="black100" mb="16px">
            KHÓA HỌC KHÁC
          </ResponsiveTypography.Paragraph>
          <div>
            <Link href={`${environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME}/sat`} target="_blank">
              <ResponsiveTypography.Paragraph variant="medium/14-20" color="black100" mb="12px">
                SAT
              </ResponsiveTypography.Paragraph>
            </Link>

            <Link
              href={`${environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME}/toeic`}
              target="_blank"
            >
              <ResponsiveTypography.Paragraph variant="medium/14-20" color="black100" mb="12px">
                TOEIC
              </ResponsiveTypography.Paragraph>
            </Link>

            <Link
              href={`${environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME}/tieng-anh-giao-tiep`}
              target="_blank"
            >
              <ResponsiveTypography.Paragraph variant="medium/14-20" color="black100" mb="12px">
                TIẾNG ANH GIAO TIÊP
              </ResponsiveTypography.Paragraph>
            </Link>
          </div>
        </RightArea>
      </Content>
    </Main>
  );
};

import { InternalPageLink } from '@doltech/routeDefinition';
import * as React from 'react';

interface LandingLayoutContextValue {
  sideBarOpen: boolean;
  setSideBarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  toggleSideBarOpen: () => void;
}

export const LandingLayoutContext = React.createContext<LandingLayoutContextValue>(null);

const contextErrorMessage = (componentName) =>
  `[${componentName}] compound components cannot be rendered outside the [${componentName}] component`;

export const useLandingLayoutContext = () => {
  const context = React.useContext(LandingLayoutContext);
  if (!context) {
    throw new Error(contextErrorMessage('Landing Layout'));
  }
  return context;
};

export const LandingContext = React.createContext<InternalPageLink>(null);

export const layoutGridOption = {
  gutter: 24,
  columnWidth: 68,
};

export const layoutGridWidthCalculator = (numberOfColumn) => {
  return (
    layoutGridOption.gutter * (numberOfColumn - 1) + layoutGridOption.columnWidth * numberOfColumn
  );
};

export const useLayoutGrid = () => {
  return {
    layoutGridWidthCalculator,
    layoutGridOption,
  };
};

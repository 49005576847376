import { TextProps as AntdTextProps } from 'antd/lib/typography/Text';
import { ColorProps, LayoutProps, PositionProps, SpaceProps, TypographyProps } from 'styled-system';
import * as React from 'react';
import { TitleProps as AntdTitleProps } from 'antd/lib/typography/Title';

import { ParagraphProps as AntdParagraphProps } from 'antd/lib/typography/Paragraph';

import { DOLThemeType } from '../Common/theme';
import { ResponsiveTypography } from '../Typography/v3/ResponsiveTypography';

const DEFAULT_LINE_HEIGHT_MAP = {
  64: 72,
  56: 64,
  48: 56,
  42: 54.6,
  40: 48,
  32: 40,
  24: 32,
  20: 28,
  18: 24,
  16: 24,
  15: 20,
  14: 20,
  13: 20,
  12: 16,
  10: 16,
};

const VARIANT_FONT_WEIGHT_MAPPING = {
  regular: 400,
  medium: 500,
  'semi-bold': 600,
  bold: 700,
  black: 900,
};

type RegularFontSize =
  | '48-60'
  | 42
  | '32-44'
  | 32
  | 24
  | '24-40'
  | 20
  | '20-32'
  | 18
  | '18-28'
  | 16
  | '16-28'
  | 15
  | 14
  | '14-24'
  | '14-28'
  | 13
  | '12-20'
  | 12
  | 10
  | '8-10'
  | '16-24';
type MediumFontSize =
  | 32
  | 24
  | '20-32'
  | 20
  | 18
  | 16
  | '16-28'
  | 15
  | 14
  | 13
  | 12
  | '12-20'
  | '10-16';
type SemiBoldFontSize =
  | 48
  | 32
  | '32-48'
  | 26
  | 24
  | '24-40'
  | '20-32'
  | '24-30'
  | '24-32'
  | 20
  | '20-28'
  | 18
  | '18-28'
  | 16
  | '16-28'
  | 14
  | 12
  | '12-20'
  | 10
  | '10-18'
  | 8
  | 40
  | '14-24'
  | '16-24'
  | '10-12';
type BoldFontSize =
  | 48
  | '48-64'
  | 40
  | 32
  | 24
  | 20
  | '20-28'
  | 18
  | '18-28'
  | 16
  | '16-28'
  | 14
  | 12
  | 10;
type BlackFontSize = 64 | 56 | 48 | 40 | 32 | 24 | '20-28' | 18 | 16 | 14 | 12;

/**
 * This type call `Template Literal Types` available on typescript 4.1.3
 */
type RegularVariant = `regular/${RegularFontSize}`;
type MediumVariant = `medium/${MediumFontSize}`;
type SemiBoldVariant = `semi-bold/${SemiBoldFontSize}`;
type BoldVariant = `bold/${BoldFontSize}`;
type BlackVariant = `black/${BlackFontSize}`;

export type DOLTypographyVariant =
  | RegularVariant
  | MediumVariant
  | SemiBoldVariant
  | BoldVariant
  | BlackVariant;

/**
 * Extends Antd typography combine with DOL custom variants
 * https://www.figma.com/file/DqRd7UQkNGcdvYL5fwZxlf/DOL-Design-Sytem-V2?node-id=2%3A453
 * https://ant.design/components/typography/
 */
export interface DOLTypographyProps
  extends SpaceProps,
    ColorProps<DOLThemeType>,
    LayoutProps,
    PositionProps,
    TypographyProps<DOLThemeType> {
  /**
   * Respect System design type. The pattern should be `variantName/fontSize[-lineHeight]
   * Ex: regular/18 means regular font-weight, font size 18px
   *     regular/18-28 means regular font-weight, font size 18px, line height 28px
   *     Default is: regular/14
   */
  variant?: DOLTypographyVariant;
  tabletVariant?: DOLTypographyVariant;
  desktopVariant?: DOLTypographyVariant;
}

// =================================================================================
// Title
// =================================================================================

export interface TitleProps extends AntdTitleProps, DOLTypographyProps {}

// =================================================================================
// Text
// =================================================================================

export interface TextProps extends AntdTextProps, DOLTypographyProps {}

// =================================================================================
// Paragraph
// =================================================================================

export interface ParagraphProps extends AntdParagraphProps, DOLTypographyProps {}

// =================================================================================
// Exports
// =================================================================================

export const Typography = {
  Paragraph: ({ variant = 'regular/14', tabletVariant, desktopVariant, ...rest }: any) => (
    <ResponsiveTypography.Paragraph
      variant={[variant, tabletVariant || variant, desktopVariant || tabletVariant || variant]}
      {...rest}
    />
  ),
  Text: ({ variant = 'regular/14', tabletVariant, desktopVariant, ...rest }: any) => (
    <ResponsiveTypography.Text
      variant={[variant, tabletVariant || variant, desktopVariant || tabletVariant || variant]}
      {...rest}
    />
  ),
  Title: ({ variant = 'regular/32', tabletVariant, desktopVariant, ...rest }: any) => (
    <ResponsiveTypography.Title
      variant={[variant, tabletVariant || variant, desktopVariant || tabletVariant || variant]}
      {...rest}
    />
  ),
};

// =================================================================================
// Utils
// =================================================================================

interface ParsedTypographyVariant {
  fontSize: number;
  fontWeight: number;
  lineHeight: string;
}

export const parseVariant = (variant: string): ParsedTypographyVariant => {
  const isIncludesLineHeight = /\d-\d/.test(variant);
  const [variantName, fontProperties] = variant.split('/');
  if (isIncludesLineHeight) {
    // Ex: regular/16-28
    const [fontSize, lineHeight] = fontProperties.split('-');
    return {
      fontSize: +fontSize,
      lineHeight: `${lineHeight}px`,
      fontWeight: VARIANT_FONT_WEIGHT_MAPPING[variantName],
    };
  }

  const fontSize = +fontProperties;

  return {
    fontSize,
    lineHeight: `${DEFAULT_LINE_HEIGHT_MAP[fontSize]}px`,
    fontWeight: VARIANT_FONT_WEIGHT_MAPPING[variantName],
  };
};

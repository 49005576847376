import cl from 'classnames';
import * as React from 'react';
import styled from 'styled-components';
import { colorsV2 } from '../../colors-v2';
import { SEARCH_PARENT_ID } from '../../SearchEngine/const/SearchConstant';
import { LandingContent } from './LandingContent';
import { LandingHeader } from './LandingHeader';
import { LandingLayoutContext } from './LandingLayout.context';

const Main = styled.div.withConfig({
  componentId: 'Layout_LandingLayout_Main',
} as any)`
  padding-top: 0;
  background-color: ${colorsV2.white100};
`;

interface LandingLayoutProps extends React.HTMLAttributes<HTMLDivElement> {
  defaultSidebarOpen?: boolean;
  isTopBarFixed?: boolean;
}

export const LandingLayout = React.forwardRef(
  (props: LandingLayoutProps, ref: React.Ref<HTMLDivElement>) => {
    const { className, children, defaultSidebarOpen, isTopBarFixed = true, ...rest } = props;
    const [sideBarOpen, setSideBarOpen] = React.useState(false);

    const toggleSideBarOpen = React.useCallback(
      () => setSideBarOpen((oldSidebarOpen) => !oldSidebarOpen),
      []
    );

    const value = React.useMemo(() => {
      return {
        sideBarOpen,
        setSideBarOpen,
        toggleSideBarOpen,
      };
    }, [sideBarOpen, toggleSideBarOpen]);

    return (
      <LandingLayoutContext.Provider value={value}>
        <Main
          ref={ref}
          className={cl(className, { 'topbar-fixed': isTopBarFixed }, SEARCH_PARENT_ID)}
          id="landing-layout"
          {...rest}
        >
          {children}
        </Main>
      </LandingLayoutContext.Provider>
    );
  }
);

export const LandingLayoutHeader = LandingHeader;
export const LandingLayoutContent = LandingContent;
